<div class="page-title section-padding">
    <h1>USLUGE</h1>
</div>
<div class="section-padding section">
    <div class="services">
        <h3>Specijalistička ordinacija Interne medicine “Kardiovaskular plus” obavlja:</h3>
        <p class="services-list">
            • Pregled specijaliste interne medicine<br>
            • Pregled kardiologa<br>
            • Pregled reumatologa<br>
            • Konsultativni pregledi<br>
            • Ultrazvučni pregled (srca, zglobova, mišića…)<br>
            • Kolor doppler krvnih sudova vrata<br>
            • 24h ambulatorni monitoring krvnog pritiska<br>
            • 24h Holter EKG-a<br>
            • Test fizičkim opterećenjem<br>
            • Stres ehokardiografski test<br>
            • Farmakološki (dobutaminski) test opterećenja<br>
            • Procena kardiovaskularnog rizika<br>
            • Laboratorija (kroz ugovore sa referentnim laboratorijama)
        </p>
        <button class="main-button-dark" [routerLink]="'/pricing'">Cenovnik</button>
    </div>
    
    <div class="services-image">
        <img class="services-collage" src="../../assets/images/services2.jpg" alt="">
    </div>
</div>

