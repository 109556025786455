import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  menuItems = [];
  showMobileMenu: boolean = false;
  innerWidth;

  constructor() {
    this.menuItems = [
      {
        name: 'Početna',
        path: '/home'
      },
      {
        name: 'O nama',
        path: '/about'
      },
      {
        name: 'Usluge',
        path: '/services'
      },
      {
        name: 'Cenovnik',
        path: '/pricing'
      },
      {
        name: 'Kontakt',
        path: '/contact'
      },
    ]
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 850 && this.showMobileMenu == true) {
      this.showMobileMenu = false;
    }
  }

  toggleMobileMenu() {
    this.showMobileMenu ? this.showMobileMenu = false : this.showMobileMenu = true;
  }

}