<!-- <div *ngIf="innerWidth > 1200" class="info-ribbon">
    <span class="ribbon-item"><i class="material-icons">phone</i> 027/32 47 22, &nbsp; 062/32 47 22</span>
    <span class="ribbon-item"><i class="material-icons">home</i>Dragoljuba Rakića BB, Prokuplje</span>
    <span class="ribbon-item"><i class="material-icons">facebook</i></span>
</div> -->
<nav class="main-menu">
    <span class="logo-container">
        <img class="menu-logo" src="assets/images/logo-final.png" alt="KARDIOVASKULAR" routerLink="/home">
        <!-- <span>KARADIOVASKULAR +</span> -->
    </span>

    <span class="menu-links-container">
        <ul>
            <li *ngFor="let item of menuItems">
                <a [routerLink]="item.path">{{ item.name }}</a>
            </li>
        </ul>
    </span>

    <span class="mobile-menu-container">
        <i *ngIf="!showMobileMenu" class="material-icons" (click)="toggleMobileMenu()">menu</i>
        <i *ngIf="showMobileMenu" class="material-icons" (click)="toggleMobileMenu()">close</i>
    </span>
</nav>

<div class="mobile-menu-links" *ngIf="showMobileMenu && innerWidth < 1200">
    <ul>
        <li (click)="toggleMobileMenu()" *ngFor="let item of menuItems">
            <a [routerLink]="item.path">{{ item.name }}</a>
        </li>
    </ul>
</div>