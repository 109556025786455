<div class="page-title section-padding">
    <h1>CENOVNIK</h1>
</div>

<div class="section-padding">
    <table class="pricing-table">
        <tr>
            <th>Usluga</th>
            <th>Cena</th>
        </tr>
        <tr *ngFor="let service of services; let i = index">
           <td>{{ service.name }}</td>
           <td>{{ service.price }}</td> 
        </tr>
    </table>
</div>