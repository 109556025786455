import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  phone: string;
  message: string;

  nameError: boolean = false;
  emailError: boolean = false;
  phoneError: boolean = false;
  messageError: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  sendMail() {
    if (!this.name) {
      this.nameError = true;
    } else {
      this.nameError = false;
    }

    if (!this.email) {
      this.emailError = true;
    } else {
      this.emailError = false;
    }
    
    if (!this.phone) {
      this.phoneError = true;
    } else {
      this.phoneError = false;
    }
    
    if (!this.message) {
      this.messageError = true;
    } else {
      this.messageError = false;
    }
    
    if (!this.name || !this.email || !this.phone || !this.message) {
      return;
    }

    this.sendEmail();
  }

  async sendEmail() {
    const requestBody = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      message: this.message
    };
    console.log("request body:", requestBody);
    const response = await fetch("/emailer/send_email.php", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(requestBody)
    });

    const json = await response.json();
    if ("message" in json && json.message === "success") {
      console.log("success");
      alert("Poruka je uspešno poslata.");
    }

    if ("error" in json) {
      console.log(json.error);
      alert("Došlo je do greške, pokušajte ponovo kasnije.");
    }
  }

}
