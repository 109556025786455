<div class="page-title section-padding">
    <h1>O NAMA</h1>
</div>
<div id="about" class="section-padding">
    <p class="about-text">
        <img src="../../assets/images/image2.jpg" class="about-image" alt="">
        Tokom 1997. godine u Prokuplju je osnovana Specijalistička internistička
        ordinacija ʺSveti Vrači”, kao rezultat želja i potreba da se u okviru zdravstvene usluge
        pacijentima omogući jedan drugačiji pristup lečenju u čijem će središtu biti čovek i
        njegovo srce u punom smislu značenja te reči. Njen osnivač je bio čuveni internista -
        kardiolog Topličkog okruga dr Slobodan Milosavljević. Od tada ordinacija je promenila
        ime u “Kardiovaskular plus” ali su njena stremljenja i želje i dalje iste. Prvenstveno rad
        ordinacije je okrenut Vašem srcu i svemu onom što ga muči. U njoj od eminetnih
        stručnjaka koji se tom oblašću bave čitav svoj radni vek možete da saznate sve što
        niste znali ili niste imali gde da pitate o stanju i radu vašeg srca. Potrudićemo se da
        tokom kontinuiranog rada proširimo polje delovanja, uključimo i druge stručnjake iz svih
        oblasti interne medicine (reumatologija i endokrinologija već rade iskusni stričnjaci,
        gastroenterologija, hematologija …).<br><br>
        Smatramo da je potreba čoveka za razumevanjem njegovog stanja iskonska
        koliko i samo postojanje ljudskog roda. Zato ako želite da saznate sve o vašem zdravlju
        kroz stručan pregled i toplu reč ordinacija “Kardiovaskular plus” je tu za Vas. Kvalitet
        garantuje naš rad od preko 24 godina na istom mestu, uvek sa istim ciljem – za dobrobit
        svakog bolesnika.
    </p>
</div>

<div class="section-padding about-text" style="margin-top:-80px">
    <h3>Lekari specijalističke ordinacije Interne medicine “Kardiovaskular plus”:</h3>

    <b>Kardiologija:</b><br>
    Dr Ljubiša Nikolić internista - kardilog<br>
    Dr Bojan Ilić internista - kardiolog<br><br>

    <b>Reumatologija:</b><br>
    Dr Ljiljana Tadić internista - reumatolog<br><br>
</div>