<div id="main-section">

    <img src="../../assets/images/logo-final-black.png" class="main-logo" alt="LOGO">

    <div class="main-slogan">Sa nama kroz život.</div>

    <div class="buttons-container">
        <button class="main-button-dark services-button" [routerLink]="'/services'">Usluge</button>
        <button class="main-button-dark" [routerLink]="'/contact'">Kontakt</button>
    </div>

    <div class="bounce">
        <i class="material-icons">expand_more</i>
    </div>
    
</div>

<div id="about-section" class="section-padding">
    <div class="about-element">
        <span class="icon"><i class="material-icons">verified</i></span>
        <span class="icon-text">Kvalitet</span>
    </div>

    <div class="about-element">
        <span class="icon"><i class="material-icons">accessibility_new</i></span>
        <span class="icon-text">Humanost</span>
    </div>

    <div class="about-element">
        <span class="icon"><i class="material-icons">medical_services</i></span>
        <span class="icon-text">Profesionalnost</span>
    </div>
</div>

<div id="doctors-section">
    <div class="doctors-image">
        <img class="doctor-image" src="../../assets/images/entrance.jpg" alt="">
    </div>
    <div class="doctors-text">
        <div class="elems">
            <h2>O nama</h2>
            <p style="text-align: justify;margin-bottom: 40px;font-size: 14pt;">
                Tokom 1997. godine u Prokuplju je osnovana Specijalistička internistička
                ordinacija ʺSveti Vrači”, kao rezultat želja i potreba da se u okviru zdravstvene usluge
                pacijentima omogući jedan drugačiji pristup lečenju u čijem će središtu biti čovek i
                njegovo srce u punom smislu značenja te reči. Njen osnivač je bio čuveni internista -
                kardiolog Topličkog okruga dr Slobodan Milosavljević. Od tada ordinacija je promenila
                ime u “Kardiovaskular plus” ali su njena stremljenja i želje i dalje iste.
            </p>
            <button class="main-button-dark" [routerLink]="'/about'">Saznajte više</button>
        </div>
    </div>
</div>

<div id="cta" class="section-padding">
    <h2>POGLEDAJTE &nbsp; CENOVNIK &nbsp; NAŠIH &nbsp; USLUGA</h2>
    <button class="main-button-light" [routerLink]="'/pricing'">Cenovnik</button>
</div>

<div id="services-section" class="section-padding">
    <div class="services-list">
        <h2>Usluge</h2><br>

        <p style="font-size: 14pt;">
            • Pregled specijaliste interne medicine<br><br>
            • Pregled kardiologa<br><br>
            • Ultrazvučni pregled<br><br>
            • Procena kardiovaskularnog rizika<br><br>
            • Kolor dopler krvnih sudova<br><br>
        </p>

        <button class="main-button-dark" [routerLink]="'/services'">Sve usluge</button>

    </div>
    <div class="services-image">
        <img class="services-collage" src="../../assets/images/services.jpg" alt="">
    </div>
</div>

<!-- <div id="numbers-section" class="section-padding">

</div> -->