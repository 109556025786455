import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear: number;
  menuItems = [];

  constructor() {
    this.currentYear = new Date().getFullYear();
    this.menuItems = [
      {
        name: 'Početna',
        path: '/home'
      },
      {
        name: 'O nama',
        path: '/about'
      },
      {
        name: 'Usluge',
        path: '/services'
      },
      {
        name: 'Cenovnik',
        path: '/pricing'
      },
      {
        name: 'Kontakt',
        path: '/contact'
      },
    ]
  }

  ngOnInit(): void {
  }

}
